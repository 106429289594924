<template>
  <b-carousel-item>
    <section id="accomplish" class="hero is-fullheight is-dark">
      <div class="hero-body slide-body has-text-black is-relative">
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
             viewBox="0 0 823 684" style="enable-background:new 0 0 823 684;" xml:space="preserve" class="is-absolute">

          <g id="accomplish-layer">
            <g id="triangle-lines_1_" class="animate__animated animate__fadeInTopRight _2">
              <g>
                <path class="blue" d="M741.36,189.7c-2.61-2.65-5.23-5.31-7.84-7.95c-8.36-8.46-16.72-16.91-25.08-25.36l-9.89,2.26
                  c13.48,13.64,26.96,27.27,40.45,40.92L741.36,189.7z"/>
              </g>
              <g>
                <path class="blue" d="M736.31,210.75c-1.27-1.29-2.54-2.57-3.81-3.86c-15.04-15.23-30.07-30.46-45.11-45.69l-10.86,2.49
                  c19.06,19.28,38.12,38.56,57.19,57.85L736.31,210.75z"/>
              </g>
              <g>
                <path class="blue" d="M731.06,232.67c-3.5-3.54-7-7.09-10.5-10.63c-13.39-13.55-26.82-27.06-40.22-40.61
                  c-5-5.06-10-10.12-15-15.19l-11.23,2.57c24.73,25.02,49.48,50.05,74.25,75.11L731.06,232.67z"/>
              </g>
              <g>
                <path class="blue" d="M751.71,146.48l-9.1,2.08c2.31,2.34,4.61,4.67,6.92,7L751.71,146.48z"/>
              </g>
              <g>
                <path class="blue" d="M746.52,168.16c-5.51-5.57-11.01-11.14-16.51-16.71l-10.4,2.38c8.14,8.24,16.28,16.47,24.42,24.71
                  L746.52,168.16z"/>
              </g>
              <g>
                <path class="blue" d="M689.24,407.14c-10.53-10.66-21.06-21.31-31.59-31.97c-13.48-13.65-26.93-27.32-40.4-40.97
                  c-12.72-12.88-25.46-25.75-38.18-38.63c-9.19-9.3-18.37-18.62-27.56-27.92c-14.99-15.16-29.99-30.32-45-45.47
                  c-5.27-5.32-10.54-10.64-15.81-15.95l-10.11,2.31c7.25,7.35,14.49,14.72,21.76,22.06c24.1,24.36,48.21,48.71,72.31,73.07
                  c5.69,5.75,11.37,11.5,17.05,17.25l95.75,93.64L689.24,407.14z"/>
              </g>
              <g>
                <path class="blue" d="M682.87,239.06c-14.95-15.12-29.93-30.21-44.89-45.32c-5.71-5.77-11.41-11.54-17.12-17.31l-10.1,2.31
                  c1.53,1.55,3.07,3.1,4.6,4.65c27.16,27.47,54.3,54.95,81.46,82.43c7.06,7.14,14.11,14.28,21.17,21.42l2.43-10.14
                  C707.89,264.41,695.39,251.73,682.87,239.06z"/>
              </g>
              <g>
                <path class="blue" d="M639.66,288.96c19.62,19.85,39.2,39.73,58.81,59.58c1.29,1.31,2.58,2.61,3.88,3.92l2.53-10.56
                  c-12.07-12.19-24.15-24.36-36.21-36.56c-18.01-18.21-35.99-36.45-54-54.67c-16.41-16.6-32.85-33.16-49.26-49.76
                  c-3.16-3.2-6.32-6.41-9.48-9.62l-10.43,2.39c10.98,11.1,21.91,22.26,32.89,33.37C598.81,247.69,619.25,268.31,639.66,288.96z"/>
              </g>
              <g>
                <path class="blue" d="M725.68,255.1c-0.2-0.2-0.39-0.4-0.59-0.6c-9.57-9.68-19.12-19.38-28.69-29.05
                  c-17.83-18.02-35.66-36.04-53.49-54.06l-10.85,2.48c30.33,30.69,60.67,61.37,91.02,92.08L725.68,255.1z"/>
              </g>
              <g>
                <path class="blue" d="M568.12,243.12c15.09,15.27,30.2,30.52,45.29,45.78c15.18,15.35,30.34,30.71,45.52,46.06
                  c12.77,12.92,25.55,25.84,38.32,38.76l2.44-10.16c-0.48-0.48-0.96-0.96-1.43-1.45c-17.4-17.59-34.78-35.2-52.17-52.79
                  c-15.37-15.55-30.76-31.08-46.13-46.63c-14.37-14.54-28.74-29.09-43.11-43.63c-7.52-7.61-15.04-15.22-22.56-22.83l-10.18,2.33
                  C538.78,213.42,553.44,228.27,568.12,243.12z"/>
              </g>
              <g>
                <path class="blue" d="M597.24,300.1c16.21,16.41,32.45,32.8,48.67,49.21c15.35,15.52,30.68,31.06,46.02,46.58l2.63-10.99
                  c-15.86-16.06-31.72-32.12-47.58-48.18c-20.92-21.18-41.84-42.35-62.76-63.51c-16.51-16.69-33.03-33.37-49.53-50.07
                  c-7.25-7.34-14.5-14.68-21.75-22.03l-10.98,2.51c12.01,12.15,24.03,24.3,36.03,36.46C557.75,260.1,577.48,280.11,597.24,300.1z"
                />
              </g>
              <g>
                <path class="blue" d="M672.99,255.6c-16.83-17.02-33.64-34.07-50.47-51.09c-7.65-7.74-15.31-15.47-22.97-23.21l-11.01,2.52
                  c6.41,6.48,12.81,12.95,19.22,19.43c23.24,23.52,46.47,47.05,69.71,70.57c11.73,11.87,23.46,23.73,35.19,35.59l2.64-11.03
                  C701.2,284.12,687.1,269.86,672.99,255.6z"/>
              </g>
              <g>
                <path class="blue" d="M673.92,284.06c-10.66-10.79-21.34-21.54-32-32.32c-10.88-11.01-21.74-22.06-32.62-33.07
                  c-10.64-10.77-21.3-21.52-31.95-32.28l-10.22,2.34c18.46,18.67,36.93,37.34,55.39,56.01c22.78,23.04,45.55,46.07,68.32,69.12
                  c5.57,5.64,11.14,11.29,16.7,16.93l2.44-10.17c-2.61-2.64-5.23-5.28-7.84-7.92C692.72,303.16,683.34,293.59,673.92,284.06z"/>
              </g>
            </g>
            <g id="grid_2_" class="animate__animated animate__fadeIn animate__slower _2">
              <g>
                <line class="blue-stroke" x1="42.93" y1="118.59" x2="168.2" y2="676.63"/>
                <line class="blue-stroke" x1="102.21" y1="105.28" x2="227.48" y2="663.32"/>
                <line class="blue-stroke" x1="161.5" y1="91.97" x2="286.77" y2="650.01"/>
                <line class="blue-stroke" x1="220.78" y1="78.66" x2="346.06" y2="636.7"/>
                <line class="blue-stroke" x1="280.07" y1="65.35" x2="405.34" y2="623.39"/>
                <line class="blue-stroke" x1="339.35" y1="52.04" x2="464.63" y2="610.08"/>
              </g>
              <g>
                <line class="blue-stroke" x1="390.61" y1="83.92" x2="18.79" y2="167.39"/>
                <line class="blue-stroke" x1="403.91" y1="143.21" x2="32.1" y2="226.68"/>
                <line class="blue-stroke" x1="417.22" y1="202.49" x2="45.4" y2="285.96"/>
                <line class="blue-stroke" x1="430.53" y1="261.78" x2="58.71" y2="345.25"/>
                <line class="blue-stroke" x1="443.84" y1="321.06" x2="72.02" y2="404.53"/>
                <g>
                  <line class="blue-stroke" x1="457.63" y1="382.5" x2="85.81" y2="465.97"/>
                  <line class="blue-stroke" x1="470.94" y1="441.78" x2="99.12" y2="525.25"/>
                  <line class="blue-stroke" x1="484.25" y1="501.07" x2="112.43" y2="584.54"/>
                  <line class="blue-stroke" x1="497.56" y1="560.35" x2="125.74" y2="643.82"/>
                </g>
              </g>
            </g>
            <g id="acc-pink-rect" class="animate__animated animate__fadeInRight _3">
              <rect x="705.01" y="350.18" class="black" width="106.1" height="318.15"/>
              <rect x="695.78" y="336.48" class="red black-stroke small-stroke" width="106.1" height="318.15"/>
            </g>
            <g id="acc-blue-rect" class="animate__animated animate__fadeInLeft _3">

                <rect x="134.42" y="388.04" transform="matrix(-3.545574e-07 1 -1 -3.545574e-07 780.4726 352.5173)"
                      class="black" width="159.11" height="356.91"/>

              <rect x="121.8" y="368.84" transform="matrix(-3.545574e-07 1 -1 -3.545574e-07 748.6483 345.9457)"
                    class="blue black-stroke small-stroke" width="159.11" height="356.91"/>
            </g>
            <g id="circle_3_" class="animate__animated animate__fadeInDown _3">
              <circle class="black" cx="229.69" cy="201.56" r="75.87"/>
              <circle class="red black-stroke small-stroke" cx="218.58" cy="186.03" r="75.87"/>
            </g>
            <g id="acc-text" class="animate__animated animate__fadeIn _4">
              <rect x="61.18" y="237.21" class="black" width="549.79" height="346.38"/>
              <g>
                <rect x="37.52" y="223.54" class="white" width="545.79" height="342.38"/>
                <path class="black"
                      d="M581.31,225.54v338.38H39.52V225.54H581.31 M585.31,221.54H35.52v346.38h549.79V221.54L585.31,221.54z"/>
              </g>
              <rect x="58.17" y="241" class="no-fill" width="518.31" height="336.05"/>
              <text transform="matrix(1 0 0 1 58.1732 254.1776)"><tspan x="0" y="0" class="blue1 lufga black svg-text-18">Pitch: Postmates for math tutors. Imagine studying for a </tspan>
                <tspan x="0" y="21" class="blue1 lufga black svg-text-18">math exam and you’re stumped on a problem. How will you </tspan>
                <tspan x="0" y="42" class="blue1 lufga black svg-text-18">solve this type of problem when you encounter it on the </tspan>
                <tspan x="0" y="63" class="blue1 lufga black svg-text-18">exam? With Accomplish’s mobile app, you can request a </tspan>
                <tspan x="0" y="84" class="blue1 lufga black svg-text-18">math tutor to come to you on demand and explain it to you </tspan>
                <tspan x="0" y="105" class="blue1 lufga black svg-text-18">one-on-one.</tspan>
                <tspan x="0" y="147" class="blue1 lufga black svg-text-18">In sophomore year of college, I designed the app and </tspan>
                <tspan x="0" y="168" class="blue1 lufga black svg-text-18">website interface for math tutoring startup Accomplish. Still </tspan>
                <tspan x="0" y="189" class="blue1 lufga black svg-text-18">new to the world of UI, I spent much of my time reading </tspan>
                <tspan x="0" y="210" class="blue1 lufga black svg-text-18">case studies and learning best practices. My goals at the </tspan>
                <tspan x="0" y="231" class="blue1 lufga black svg-text-18">time were: ease of use, simplicity, and brand consistency. </tspan>
                <tspan x="0" y="252" class="blue1 lufga black svg-text-18">Which looking back should be a given regardless of </tspan>
                <tspan x="0" y="273" class="blue1 lufga black svg-text-18">organization principles, but I like returning to this project </tspan>
                <tspan x="0" y="294" class="blue1 lufga black svg-text-18">every now and then to see what I would do differently today.</tspan></text>
            </g>
            <g id="circles2_4_" class="animate__animated animate__fadeIn _5 ">
              <g>
                <circle class="yellow black-stroke small-stroke" cx="95.5" cy="133.21" r="6.59"/>
                <circle class="yellow black-stroke small-stroke" cx="114.31" cy="133.21" r="6.59"/>
                <circle class="yellow black-stroke small-stroke" cx="133.12" cy="133.21" r="6.59"/>
                <circle class="yellow black-stroke small-stroke" cx="151.92" cy="133.21" r="6.59"/>
                <circle class="yellow black-stroke small-stroke" cx="170.73" cy="133.21" r="6.59"/>
                <circle class="yellow black-stroke small-stroke" cx="189.53" cy="133.21" r="6.59"/>
                <circle class="yellow black-stroke small-stroke" cx="208.34" cy="133.21" r="6.59"/>
                <circle class="yellow black-stroke small-stroke" cx="227.15" cy="133.21" r="6.59"/>
                <circle class="yellow black-stroke small-stroke" cx="245.95" cy="133.21" r="6.59"/>
                <circle class="yellow black-stroke small-stroke" cx="264.76" cy="133.21" r="6.59"/>
                <circle class="yellow black-stroke small-stroke" cx="283.56" cy="133.21" r="6.59"/>
              </g>
              <g>
                <circle class="yellow black-stroke small-stroke" cx="123.02" cy="163.5" r="6.59"/>
                <circle class="yellow black-stroke small-stroke" cx="141.82" cy="163.5" r="6.59"/>
                <circle class="yellow black-stroke small-stroke" cx="160.63" cy="163.5" r="6.59"/>
                <circle class="yellow black-stroke small-stroke" cx="179.44" cy="163.5" r="6.59"/>
                <circle class="yellow black-stroke small-stroke" cx="198.24" cy="163.5" r="6.59"/>
                <circle class="yellow black-stroke small-stroke" cx="217.05" cy="163.5" r="6.59"/>
              </g>
              <g>
                <circle class="yellow black-stroke small-stroke" cx="55.92" cy="193.79" r="6.59"/>
                <circle class="yellow black-stroke small-stroke" cx="74.72" cy="193.79" r="6.59"/>
                <circle class="yellow black-stroke small-stroke" cx="93.53" cy="193.79" r="6.59"/>
                <circle class="yellow black-stroke small-stroke" cx="112.33" cy="193.79" r="6.59"/>
                <circle class="yellow black-stroke small-stroke" cx="131.14" cy="193.79" r="6.59"/>
                <circle class="yellow black-stroke small-stroke" cx="149.95" cy="193.79" r="6.59"/>
              </g>
              <g>
                <circle class="yellow black-stroke small-stroke" cx="144.31" cy="102.92" r="6.59"/>
                <circle class="yellow black-stroke small-stroke" cx="163.12" cy="102.92" r="6.59"/>
                <circle class="yellow black-stroke small-stroke" cx="181.92" cy="102.92" r="6.59"/>
                <circle class="yellow black-stroke small-stroke" cx="200.73" cy="102.92" r="6.59"/>
                <circle class="yellow black-stroke small-stroke" cx="219.53" cy="102.92" r="6.59"/>
                <circle class="yellow black-stroke small-stroke" cx="238.34" cy="102.92" r="6.59"/>
              </g>
            </g>
            <g id="zigzag_3_" class="animate__animated animate__fadeIn _5 ">
              <polygon class="black" points="121.84,664.28 137.85,677.41 134.48,648.65 163.23,645.38 159.06,615.96 188.51,612.46
                184.67,583.85 213.11,581.33 196.47,567.89 168.6,571.16 172.14,600.38 142.91,603.92 148.04,633.21 117.89,636.55 		"/>
              <polygon class="yellow black-stroke small-stroke" points="117.07,654.35 133.09,667.48 129.71,638.72 158.47,635.45 154.29,606.03 183.74,602.53
                179.91,573.92 208.34,571.39 191.7,557.96 163.84,561.23 167.37,590.45 138.14,593.99 143.27,623.28 113.13,626.62 		"/>
            </g>
            <g>

                <image style="overflow:visible;" width="900" height="602" xlink:href="../assets/accomplish-logos.png"
                       transform="matrix(0.257 0 0 0.257 320.6522 54.21)" class="animate__animated animate__fadeIn _6">
              </image>

              <image style="overflow:visible;" width="154" height="271" xlink:href="../assets/accomplish-login.png"
                     transform="matrix(1 0 0 1 635.5181 57.9225)" class="animate__animated animate__fadeIn _6">
              </image>

              <image style="overflow:visible;" width="152" height="270" xlink:href="../assets/accomplish-map.png"
                     transform="matrix(1 0 0 1 636.5181 345.0409)" class="animate__animated animate__fadeIn _6">
              </image>
            </g>
            <g id="triangle_2_" class="animate__animated animate__fadeIn _5 ">
              <polygon class="black" points="627.07,683.6 548.87,573.61 501.92,646.87 		"/>
              <polygon class="yellow black-stroke small-stroke" points="610.69,670.17 532.49,560.18 485.54,633.44 		"/>
            </g>
          </g>
        </svg>
        <h1 class="title is-1 has-text-grey-dark is-highlighted yellow-highlight animate__animated animate__fadeInDown _2">
          case study: accomplish</h1>
      </div>
    </section>
  </b-carousel-item>
</template>

<script>
export default {
  name: "Accomplish"
}
</script>
